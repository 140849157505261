<template>
<div class="Preview">
	<el-carousel height="350px" :indicator-position="'none'" :initial-index="initialIndex" @change="IndexChange" ref="elcarousel">
	  <el-carousel-item v-for="item in images" :key="item">
		<div class="SliderPicture">
			<img :src="item" />
		</div>
	  </el-carousel-item>
	</el-carousel>
	
	<div class="Thumbs">
		
		<template v-for="(item,itemI) in images">
			<div  :key="item" @click="SetActiveItem(itemI)" :class="itemI == initialIndex ? 'On':''">
				<img :src="item" />
			</div>
		</template>
		
	</div>
	
</div>
</template>

<script>
	import {Carousel,CarouselItem} from 'element-ui'
	export default {
	  name: 'GoodsPreviewComponent',
	  props: {
		images:{
			type:Array,
			default:function(){
				return []
			}
		},
	  },
	  data() {
	      return {
			  initialIndex:0
	      }
	    },
	  components: {
		  'el-carousel':Carousel,
		  'el-carousel-item':CarouselItem
	  },
	  methods:{
		  IndexChange(_newIndex,_oldIndex){
			  this.initialIndex = _newIndex
		  },
		  SetActiveItem(_index){
			  this.$refs.elcarousel.setActiveItem(_index)
		  }
	  }
	}
</script>

<style scoped>
.Preview{
	width: 100%;
	border:1px solid rgba(0,0,0,0.02);
}

.SliderPicture{
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.SliderPicture img{
	height: 100%;
}
  .Thumbs{
	  width: 100%;
	  display: flex;
	  margin-top: 20px;
  }
  .Thumbs div{
	  width: 80px;
	  height: 80px;
	  display: flex;
	  margin-right: 10px;
	  justify-content: center;
	  align-items: center;
	  border: 2px solid rgba(0,0,0,0.04);
	  cursor: pointer;
  }
  .Thumbs div:hover,.Thumbs div.On{
	  border: 2px solid rgba(23,134,65,1);
  }
    .Thumbs div img{
		width: 100%;
	}
</style>
